// import external dependencies
import 'jquery';

// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

document.addEventListener('DOMContentLoaded', function () {

  // Get all "navbar-burger" elements
  var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach(function ($el) {
      $el.addEventListener('click', function () {

        // Get the target from the "data-target" attribute
        var target = $el.dataset.target;
        var $target = document.getElementById(target);

        // Toggle the class on both the "navbar-burger" and the "navbar-menu"
        $el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }

});

$(window).scroll(function () {
  var scroll = $(window).scrollTop();
  if (scroll > 1) {
    $('.banner .navbar').addClass('fixedheader');
  }
  if (scroll < 1) {
    $('.banner .navbar').removeClass('fixedheader');
  }
});

$('#go-top').on('click', function () {
  $('html, body').animate({ scrollTop: 0 });
});

$(document).ready(function () {
  $('.tabs ul li').on('click', function () {
    var target = $(this).attr('data-target'); $(this).siblings('li').removeClass('is-active');
    $(this).addClass('is-active');
    $('.tab-content').removeClass('is-active');
    $('#' + target).addClass('is-active');
  })
});

jQuery(document).ready(function () {
  // run the accordion
  var allPanels = jQuery('.accord-content').hide();
  var heads = jQuery('.accordion header');
  jQuery(heads).on('click', function () {
    var $this = jQuery(this);
    var $target = $this.parent().find('div.accord-content');
    if (!$target.hasClass('active')) {
      heads.removeClass('selected');
      $this.addClass('selected');
      allPanels.removeClass('active').slideUp();
      $target.addClass('active').slideDown();
    }
  });
});


// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import { faFacebookF, faTwitter, faInstagram, faYoutube, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faAngleUp, faLocationDot, faPhone, faEnvelope } from '@fortawesome/pro-solid-svg-icons';


// add the imported icons to the library
library.add(faFacebookF, faTwitter, faAngleUp, faLocationDot, faPhone, faEnvelope, faInstagram, faYoutube, faLinkedin, faTiktok);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();
